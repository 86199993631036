import React from "react"
import Section from "../../../../components/shared/Section"
import SectionHeading from "../../../../components/shared/Sectionheading"
import { Card, Col, Container, Image, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import Paragraph from "../../../../components/shared/Paragraph"
import Buttons from "../../../../components/shared/Button"
import { navigate } from "gatsby"

const MindCards = ({ title, text, cardData, cta }) => {
	return (
		<Section>
			<Container>
				<Row className="justify-content-center">
					<Col md={6}>
						<SectionHeading className="text-center">{title}</SectionHeading>
						<Paragraph className="text-center">{text}</Paragraph>
					</Col>
				</Row>
				<Row className="mt-5">
					{cardData.map((cards) => (
						<Col md={4}>
							<Card className={styles.cards}>
								<Card.Body>
									<Image src={cards.image} fluid />
									<h4 className="mt-3">{cards.title}</h4>
									<Paragraph>{cards.text}</Paragraph>
									<a href={cards.path} className="text-secondary pb-4 fw-bold">
										Read more
									</a>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
				<Row className="mt-5">
					<Col md={12}>
						<div className="text-center">
							<Buttons href={'https://content.galeahealth.com/'} >{cta}</Buttons>
						</div>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default MindCards

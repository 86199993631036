import React from "react"
import Section from "../../../../components/shared/Section"
import SectionHeading from "../../../../components/shared/Sectionheading"
import { Col, Container, Image, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import Paragraph from "../../../../components/shared/Paragraph"

const Support = ({ title, text, image }) => {
	return (
		<Section className={styles.supportbg}>
			<Container>
				<Row>
					<Col md={6} className="mt-md-5 mt-0 mb-md-5 mb-0 pt-md-5 pt-0 pb-md-5 pb-0">
						<SectionHeading className="mt-md-5 mt-0 pt-md-5 pt-0  text-md-start text-center ">{title} </SectionHeading>
						<Paragraph className="text-md-start text-center ">{text}</Paragraph>
					</Col>
					<Col md={6} className="ps-0">
						<Image src={image} fluid />
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default Support

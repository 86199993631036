import React from "react"
import athlete from "../assets/images/athlete.png"
import athlete2 from "../assets/images/athlete2.png"
import support_img from "../assets/images/support_img.png"
import coach_img from "../assets/images/coach.png"
import mindCard_img1 from "../assets/images/mind_cards_img1.png"
import mindCard_img2 from "../assets/images/mind_cards_img2.png"
import mindCard_img3 from "../assets/images/mind_cards_img3.png"

export const content = {
	// ATHLETES
	athlete: {
		title: "ATHLETES",
		text: "Galea offers support ranging from personalized educational resources to athlete-aligned professionals. If you are looking for mental health support or to improve your performance, Galea connects you with a therapist or mental performance coach who understands your unique experience as an athlete.",
		img: athlete,
		cta: "Find support",
	},

	// THE IMPORTANCE OF ATHLETE MENTAL HEALTH SUPPORT
	support: {
		title: "THE IMPORTANCE OF ATHLETE MENTAL HEALTH SUPPORT",
		text: (
			<>
				As an athlete, you dedicate countless hours to training your body, but it’s easy to forget about taking care of
				your mind. <br />
				<br />
				At Galea Health, we recognize the importance of offering current and former athletes access to holistic,
				personalized mental health care and performance support.
				<br />
				<br /> 
			</>
		),
		img: support_img,
	},

	// THERAPISTS & PERFORMANCE COACHES
	coaches: {
		title: "THERAPISTS & PERFORMANCE COACHES",
		text: "If you are a licensed mental health professional or certified mental performance coach with experience working with athletes, apply to join the Galea Health network.",
		img: coach_img,
		cta: "Join our network",
	},

	// For teams & Atheletics program
	teams: {
		title: "Schools, Teams & Athletic Programs",
		text: "Galea offers program-wide support through mental health literacy and advocacy programs for school personnel, athletes, parents, and coaches. Galea also offers programs custom-built therapist and performance coach referral networks.",
		img: athlete2,
		cta: "Find support",
	},

	// MIND MATTERS
	mindCard: {
		title: "Mind Matters",
		text: "The Galea Health blog contains a range of resources that cover many of the common mental health and wellness challenges that athletes face.",
		cards: [
			{
				title: "How to Talk to a Teammate about Mental Health",
				text: "Nine ways to start an open conversation about mental health. Timing is important. Find a quiet, comfortable, setting and give your undivided attention",
				image: mindCard_img1,
				path: "https://content.galeahealth.com/2021/12/09/how-to-talk-to-a-teammate-about-mental-health/",
			},
			{
				title: <> Therapist Q&A Marissa Norman <br/> <br /></>,
				text: "Dr. Marissa Norman is a Sport Psychologist holding a M.S. in Sport and Exercise Psychology and a Doctoral Degree in Clinical Psychology. Marissa has 7 ",
				image: mindCard_img2,
				path: "https://content.galeahealth.com/2021/08/19/therapist-qa-marissa-norman/",
			},
			{
				title: <> Eating Disorder Support Guide<br/> <br /></>,
				text: "Navigating conversations about eating disorders can be challenging, and athletes can feel particularly motivated to hide their thoughts and behaviors",
				image: mindCard_img3,
				path: "https://content.galeahealth.com/2021/12/09/eating-disorder-support-guide/",
			},
		],
		cta: "See all blogs",
	},

	// Subscribe
	subscribe: {
		title: "Subscribe",
		text: "If you are a licensed mental health professional or certified mental performance coach with experience working with athletes, we invite you to apply to join the Galea Health network.",
	},
}

import React from "react"
import { Button } from "react-bootstrap"
import * as styles from "./styles.module.scss"

const Buttons = ({ className, children, onClick, clickable, ...props }) => {
	return (
		<Button href variant="primary" onClick={clickable ? onClick:()=>{}}  className={`${styles.button} ${className}`} {...props}>
			{children}
		</Button>
	)
}

export default Buttons

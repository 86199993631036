import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import Section from "../../../../components/shared/Section"
import HeroHeading from "../../../../components/shared/HeroHeading"
import { navigate } from "gatsby"

const Hero = () => {
	return (
		<Section className={styles.heroBg}>
			<Container>
				<Row>
					<Col md={6}>
						<div className={styles.parrent}>
							<div className={styles.heroTeext}>
								<HeroHeading className="fw-bold text-uppercase">
								CONNECTING ATHLETES AND ORGANIZATIONS WITH MENTAL HEALTH AND PERFORMANCE SUPPORT
								</HeroHeading>
								<div className="text-md-start text-center">
									<Button onClick={()=>navigate('/providers')} varient="primary" className={styles.btnProvider}>
										View providers
									</Button>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default Hero

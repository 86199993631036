import React from "react"
import { Col, Container, Image, Row } from "react-bootstrap"
import SectionHeading from "../../../../components/shared/Sectionheading"
import Section from "../../../../components/shared/Section"
import * as styles from "./styles.module.scss"
import Paragraph from "../../../../components/shared/Paragraph"
import Buttons from "../../../../components/shared/Button"
import { navigate } from "gatsby"

const SchoolAthlete = ({ title, text, image, cta }) => {
    return ( 
        <Section className={styles.bg}>
			<Container>
				<Row>
					<Col md={5}>
						<div className="text-center mt-md-5 mt-0 pt-md-3 pt-0">
							<Image src={image} fluid width={450} />
						</div>
					</Col>

					<Col md={{ span: 6, offset: 1 }} className="mt-md-5 mt-0 pt-0 pt-md-5">
						<SectionHeading className=" pt-5 text-md-start text-center ">{title}</SectionHeading>
						<Paragraph className="text-md-start text-center">{text}</Paragraph>
						<div className="text-md-start text-center">
							<Buttons clickable onClick={()=>navigate('/providers')}> {cta}</Buttons>
						</div>
					</Col>
				</Row>
			</Container>
		</Section>
     );
}
 
export default SchoolAthlete;
import { Main } from "../components/layout"
import * as React from "react"
import Hero from "../components/pages/home/Hero"
import Athlete from "../components/pages/home/Athletes"
import Coaches from "../components/pages/home/Coaches"
import Subscribe from "../components/pages/home/Subscribe"
import Support from "../components/pages/home/Support"
import MindCards from "../components/pages/home/MindCards"
import { content } from "../content/home"
import { SSRProvider } from "react-bootstrap"
import SchoolAthlete from "../components/pages/home/SchoolAthlete"

// markup
const IndexPage = () => {
	// localStorage.setItem("name",search);
	// const name = localStorage.getItem("name");
	return (
		<>
			<SSRProvider>
			<Main>
				<Hero />
				<Athlete
					title={content.athlete.title}
					text={content.athlete.text}
					image={content.athlete.img}
					cta={content.athlete.cta}
				/>
				<Coaches
					title={content.coaches.title}
					text={content.coaches.text}
					image={content.coaches.img}
					cta={content.coaches.cta}
				/>
				<SchoolAthlete 
				    title={content.teams.title}
					text={content.teams.text}
					image={content.teams.img}
					cta={content.teams.cta}
				/>
				<Support title={content.support.title} text={content.support.text} image={content.support.img} />
				<MindCards
					title={content.mindCard.title}
					text={content.mindCard.text}
					cardData={content.mindCard.cards}
					cta={content.mindCard.cta}
				/>
				{/* <Subscribe title={content.subscribe.title} text={content.subscribe.text} /> */}
			</Main>
				</SSRProvider>
		</>
	)
}

export default IndexPage

import React from "react"
import { Col, Container, Image, Row } from "react-bootstrap"
import SectionHeading from "../../../../components/shared/Sectionheading"
import Section from "../../../../components/shared/Section"
import Paragraph from "../../../../components/shared/Paragraph"
import Buttons from "../../../../components/shared/Button"
import * as styles from "./styles.module.scss"
import { navigate } from "gatsby"

const Coaches = ({ title, text, image, cta }) => {
	return (
		<Section className={styles.bg}>
			<Container>
				<Row>
					<Col md={{ span: 6, order: 1 }} xs={{ order: 2 }} className="mt-md-5 mt-0 pt-0 pt-md-5">
						<SectionHeading className=" pt-5 text-md-start text-center">{title}</SectionHeading>
						<Paragraph className="text-md-start text-center">{text}</Paragraph>
						<div className="text-md-start text-center">
							<Buttons clickable onClick={()=>navigate('/provider-signup')}> {cta}</Buttons>
						</div>
					</Col>
					<Col md={{ span: 5, offset: 1, order: 2 }} xs={{ order: 1 }}>
						<div className="text-center">
							<Image src={image} fluid width={450} />
						</div>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default Coaches

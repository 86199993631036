import React from "react"
import * as styles from "./styles.module.scss"

const Section = ({ children, bg, style, ...props }) => {
	return (
		<section
			className={styles.section}
			style={{
				backgroundImage: bg && `url('${bg}')`,
				...style,
			}}
			{...props}
		>
			{children}
		</section>
	)
}

export default Section
